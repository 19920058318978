var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popup"
  }, [_c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "popTitle popTitleArea"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('page.transfer.popup.title.return')))]), _c('div', {
    staticClass: "buttonArea"
  }, [_c('button', {
    staticClass: "x",
    on: {
      "click": function ($event) {
        return _vm.$emit('closeCmtPopup');
      }
    }
  }, [_c('i', {
    staticClass: "micon"
  }, [_vm._v("close")])])])]), _c('ul', {
    staticClass: "cont"
  }, [_c('li', [_c('div', {
    staticClass: "titArea"
  }, [_c('p', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.$t('page.transfer.popup.subTitle.returnNote')) + " *")])]), _c('ul', {
    staticClass: "contArea"
  }, [_c('li', {
    staticStyle: {
      "padding-left": "0"
    }
  }, [_c('v-textarea', {
    attrs: {
      "flat": "",
      "solo": "",
      "density": "compact",
      "hide-details": "auto"
    },
    model: {
      value: _vm.comment,
      callback: function ($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  })], 1)])])]), _c('div', {
    staticClass: "btnW popBtn"
  }, [_c('button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "btn default",
    on: {
      "click": function ($event) {
        return _vm.$emit('closeCmtPopup');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.button.close')))]), _c('button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "btn solid",
    on: {
      "click": function ($event) {
        return _vm.changeState();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('common.button.return')))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }